<template>
  <div @click.stop.prevent="triggerChange" class="magentaSportHolder">
    <h1 class="i6-title-main" @click.stop="changeMainSlide" v-if="item.title" v-html="item.title"></h1>
    <div class="swiper-button-prev"></div>
    <swiper class="i6-item-slider"
    :options="sliderOptions"
    @slideChange="onSlideChange"
    ref="mthSlider"
    >
      <swiper-slide v-for="(subItem, subIndex) in item.slideItems"
          :key="'swiper-image-item' + subIndex">
          <img :src="$store.state.slides.baseUrl + subItem.image" />
      </swiper-slide>
    </swiper>
    <div class="swiper-button-next"></div>
    <div class="customSliderTools">
      <ul class="bullets">
        <li v-for="(item, i) in catsFilter" :key="'cf-' + i" :class="{ active: activeIndex === i }">
          <span v-text="i" @click.stop="handleSlideChange(i, item)"></span>
        </li>
      </ul>
      <ul class="scrollBar">
        <li v-for="(item, i) in catsFilter" :key="'cfs-' + i" :class="{ active: activeIndex === i }">
          <span></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: ['item'],
  data () {
    return {
      innerAnimating: false,
      cats: {},
      activeIndex: null,
      sliderOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        // loop: true,
        // initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          600: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: 3
          }
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    catsFilter () {
      let self = this
      let obj = {}
      this.item.slideItems.forEach((item, i) => {
        if (!self.cats.hasOwnProperty(item.category)) {
          obj[item.category] = i
        }
      })
      return obj
    }
  },
  methods: {
    onSlideChange (item) {
      const realIndex = this.$refs.mthSlider.$swiper.realIndex
      const realSlide = this.item.slideItems[realIndex]
      this.activeIndex = realSlide.category
      this.returnInfoBox(realSlide.info_page || null)
      this.returnVideoBox(realSlide.video_box || null)
    },
    triggerChange (e) {
      let myTarget = e.target
      if (myTarget.className.includes('i6-title')) {
        this.$emit('changeSlides', 'l')
      }
    },
    handleSlideChange (title, index) {
      this.$refs.mthSlider.$swiper.slideTo(index, 700)
      this.activeIndex = title
    },
    returnInfoBox (data) {
      this.$emit('handleInfoBox', data)
    },
    returnVideoBox (data) {
      this.$emit('handleVideoBox', data)
    },
    changeMainSlide () {
      this.$emit('changeSlides', 'l')
    }
  },
  updated () {
    this.$refs.mthSlider.$swiper.update()
  },
  mounted () {
    this.activeIndex = this.item.slideItems[0].category
    this.returnInfoBox(this.item.slideItems[0].info_page || null)
    this.returnVideoBox(this.item.slideItems[0].video_box || null)
  }
}
</script>
